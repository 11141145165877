import { paymentsApi } from "../httpService";
import axios, { } from "axios";


const subscriptionPlansService = {
    getPlans: async (query: object): Promise<any> => {
        try {
            const result = await paymentsApi.get(`/api/v1/subscriptions/plans`, {
                params: query,
            });
            return new Promise((resolve, _) => resolve(result.data));
        } catch (error: any) {
            return new Promise((_, reject) => reject("An unexpected Error"));
        }
    },


    getPlan: async (id: number | string): Promise<any> => {
        try {
            const result = await paymentsApi.get(`/api/v1/subscriptions/plans/${id}`, {});
            return new Promise((resolve, _) => resolve(result.data.data));
        } catch (error: any) {
            if (error.response && error.response.status === 404) {
                return new Promise((_, reject) => reject(new Error('NOT_FOUND')));
            }
            return new Promise((_, reject) => reject(new Error("An unexpected Error occurred")));
        }
    },



    createPlan: async (data: object): Promise<any> => {
        try {
            const result = await paymentsApi.post('/api/v1/subscriptions/plans', data);
            return new Promise((resolve, _) => resolve(result.data.data));
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                //return new Promise((_, reject) => reject(error.response?.data?.message));
                return new Promise((_, reject) => reject(error.response?.data?.errors[0].message));
            }

            return new Promise((_, reject) => reject("An unexpected Error"));
        }
    },



    updatePlan: async (id: number, data: any): Promise<any> => {
        try {
            const result = await paymentsApi.put(`/api/v1/subscriptions/plans/${id}`, data);
            return new Promise((resolve, _) => resolve(result.data.data));
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                //return new Promise((_, reject) => reject(error.response?.data?.message));
                return new Promise((_, reject) => reject(error.response?.data?.errors[0].message));
            }
            return new Promise((_, reject) => reject('An unexpected Error'));
        }
    },



    deletePlan: async (id: number): Promise<any> => {
        try {
            const result = await paymentsApi.delete(`/api/v1/subscriptions/plans/${id}`);
            return new Promise((resolve, _) => resolve(result.data));
        } catch (error: any) {
            return new Promise((_, reject) => reject("An unexpected Error"));
        }
    },
};


export default subscriptionPlansService;